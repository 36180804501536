<template>
  <div class="profile-container">
    <div class="nav-bar"  v-if="user != null" >
      <v-row style="justify-content: space-between">
        <v-col lg="2">
          <v-img
            src="../assets/logo.svg"
            @click="logoClick()"
            style="cursor: pointer"
            width="80%"
            class="ml-5"
          ></v-img>
        </v-col>
        <v-col lg="10" v-if="userData != null && userData.uid === user.uid">
          <div class="button-column">
            <button class="button" @click="copyToClipBoard()"> Share Profile </button>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="profile-row">
      <div class="column-1">
        <div class="profile-header">
          <div class="profile-cover"></div>
          <div class="profile-picture">
            <img :src="user.profile" />
          </div>
        </div>
        <div class="profile-information">
          <div class="profile-rating">
            <v-row style="justify-content: center; margin-right: 10px">
              <v-col lg="1" v-for="n in 5" :key="n">
                <v-icon color="yellow darken-3" size="20">mdi-star</v-icon>
              </v-col>
            </v-row>
          </div>
          <h3 class="name">{{ user.name }}</h3>
          <p class="designation">{{ user.role }}</p>
          <p class="country mt-1">
            <v-icon size="15" color="white">mdi-map-marker</v-icon>
            {{ user.country }}
          </p>
          <div class="divider"></div>
        </div>
        <div class="social-information">
          <div class="social-container">
            <p>YEARS OF EXPERIENCE</p>
            <div>{{ user.yearsOfExperience }} Years</div>
          </div>
          <div class="social-container">
            <p>PORTFOLIO LINK</p>
            <div>
              <a :href="user.portfolio" target="_blank">Portfolio</a>
            </div>
          </div>
          <div class="social-container">
            <p>SOCIAL LINK</p>
            <div class="linkedin">
              <v-icon color="white" size="20" class="mr-1">mdi-linkedin</v-icon>
              <a :href="user.workUrl" target="_blank">LinkedIn</a>
            </div>
          </div>
        </div>
      </div>
      <div class="column-2">
        <skill-component :profileData="user"></skill-component>
        <user-asset-component
          :assetData="userProjects"
          class="mt-5"
        ></user-asset-component>
        <div class="custom-container mt-5">
          <v-row>
            <v-col lg="6" class="col-d-1">
              <img src="../assets/profile/project.png" class="custom-image" />
            </v-col>
            <v-col lg="6">
              <div style="padding-left: 14%; padding-top: 5%">
                <h3 class="general-text-title text">
                  Want to create custom assets for your project? (about signax)
                </h3>
                <v-row class="mt-2" style="justify-content: flex-start">
                  <v-col lg="5">
                    <button class="primary">REQUEST NOW</button>
                  </v-col>
                  <v-col lg="5">
                    <button class="secondary-btn" @click="openWebsite">
                      VISIT WEBSITE
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import SkillComponent from "../components/PublicContent/Profile/Skills.vue";
import UserAssetComponent from "../components/PublicContent/Profile/UserAssets.vue";
import { userObj } from "../services/user-service";
import { assetObj } from "../services/asset-service";

export default {
  name: "ViewProfilePublicNew",
  components: { SkillComponent, UserAssetComponent },
  data() {
    return {
      profileData: {
        specializations: [
          "3D Artist",
          "3D Animation",
          "3D Modeling",
          "3D Rendering",
          "3D Generalist (All of the above)",
        ],
        projectInterested: [
          "Gaming",
          "Metaverse",
          "AR/VR (Immersive experiences)",
          "3D Environments",
        ],
      },
      user: {},
      userProjects: [],
    };
  },
  created() {
    this.getUserProfile();
  },
  methods: {
    openWebsite() {
      window.open("https://signa-x.io", "_blank");
    },
    async getAllAssets(userId) {
      const data = await assetObj.getAllLazyMintingAssetByUser(userId);
      this.userProjects = data;
    },
    async getUserProfile() {
      const userId = this.$route.params.userId;
      const userData = await userObj.getUser(userId);
      this.user = userData.data();
      this.$store.dispatch("setTemp", this.user);
      await this.getAllAssets(userId);
    },
    async copyToClipBoard() {
      const text = this.user.uid;
      const url = `${window.location.origin}/profile/public/${text}`;
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = url;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
  },
  computed: {
    ...mapState({
      userData: (state) => state.user,
    }),
  },
};
</script>
<style scoped>
.profile-container {
  width: 100%;
  min-height: 100vh;
  /* background: #0b0c10; */
}
.profile-row {
  width: 100%;
  justify-content: space-between;
  display: flex;
  padding: 20px;
}
.column-1 {
  flex: 0.22;
  height: 95vh;
  background-color: #1b1b23;
  border-radius: 15px;
  padding: 0px 0px 0px 0px;
}
.profile-header {
  width: 100%;
  height: 25vh;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
}
.profile-cover {
  width: 100%;
  height: 18vh;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-image: url("../assets/profile/cover_update.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  border: 5px solid white;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}
.profile-picture > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profile-information {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.column-2 {
  flex: 0.75;
  height: 95vh;
  border-radius: 15px;
  margin-left: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.profile-rating {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.name {
  margin: 20px 0px 0px 0px;
  color: white;
}
.designation {
  color: #e0e0e0;
  margin: 5px 0px 0px 0px;
  font-weight: 200;
  font-size: 12px;
}
.country {
  color: #e0e0e0;
  margin: 10px 0px 20px 0px;
  font-weight: 200;
  font-size: 12px;
}
.divider {
  width: 70%;
  height: 2px;
  background: rgba(196, 196, 196, 0.31);
}
.social-information {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.social-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}
.social-container > p {
  color: #e0e0e0;
  font-size: 10px;
  font-weight: 600;
  margin: 0px 0px 5px 0px;
  text-align: left;
  width: 80%;
}
.social-container > div {
  width: 80%;
  height: 30px;
  color: #ffbf3f;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
}
a {
  color: #ffbf3f;
  text-decoration: none;
}
.linkedin {
  display: flex;
  width: 100%;
}
.custom-container {
  width: 100%;
  height: 35vh;
  border-radius: 10px;
  background-color: #1b1b23;
}
.custom-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-top: 17px;
}
.col-d-1 {
  height: 100%;
  padding: 0px;
}
.text {
  font-size: 25px !important;
  text-transform: capitalize;
}
.primary {
  background: linear-gradient(
    132.65deg,
    #ffbf3f 8.83%,
    #ff7f3f 65.1%,
    #ff3f7f 104.55%
  );
  font-family: "Archivo", sans-serif;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  color: white;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  width: 100%;
}
.secondary-btn {
  border: 1px solid var(--border-color);
  font-family: "Archivo", sans-serif;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  color: white;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
.nav-bar {
  width: 100%;
  height: 50px;
  background: var(--primary-color);
  padding: 10px;
}
.button-column{
  width:100%;
  display: flex;
  justify-content: flex-end;
}
.button{
  border: 1px solid var(--border-color);
  font-family: "Archivo", sans-serif;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  color: white;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
  width: 10%;
}
</style>
