<template>
  <div class="skills-container">
    <v-row
      class="mt-1 mb-1"
      style="justify-content: flex-end; width: 100%; margin-left: 30px"
    >
      <v-col lg="10">
        <h3 class="secondary-heading heading">Assets</h3>
      </v-col>
      <div class="mt-3" lg="1">
        <v-icon color="grey" size="30" class="left" @click="slideLeft()"
          >mdi-chevron-left</v-icon
        >
      </div>
      <v-col lg="1">
        <v-icon color="grey" size="30" class="right" @click="slideRight()"
          >mdi-chevron-right</v-icon
        >
      </v-col>
    </v-row>
    <div class="token-row" id="top-asset-cont">
      <div class="token-col" v-for="(data, index) in assetData" :key="index">
        <token-card-new :data="data" style="margin-top: 10px"></token-card-new>
      </div>
    </div>
  </div>
</template>
<script>
import TokenCardNew from "./TokenCard.vue";

export default {
  name: "User-Asset-Component",
  props: ["assetData"],
  data() {
    return {};
  },
  components: { TokenCardNew },
  methods: {
    slideRight() {
      document.getElementById("top-asset-cont").scrollBy({
        left: 500,
        behavior: "smooth",
      });
    },
    slideLeft() {
      document.getElementById("top-asset-cont").scrollBy({
        left: -500,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.skills-container {
  width: 100%;
  height: 60vh;
}
.heading {
  font-size: 20px;
  margin-left: -11%;
  width: 10%;
  font-weight: bold;
}
.content {
  background-color: #1b1b23;
}
.token-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: transform 0.5s;
}
.token-col {
  border-radius: 5px;
  max-width: 30%;
  border: 0;
  flex-basis: 33.333%;
  flex-grow: 0;
  flex-shrink: 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-right: 10px;
  margin-left: 0px;
  transition: transform 0.4s;
}
i {
  cursor: pointer;
}
</style>
