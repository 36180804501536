import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"token-container"},[_c('div',{staticClass:"token-meta"},[_c('div',{staticClass:"token-tag"},[_vm._v(" "+_vm._s(typeof _vm.data.selectedAssetType === "object" ? _vm.data.selectedAssetType[0] : _vm.data.selectedAssetType)+" ")]),_c('div',{staticClass:"token-fav",on:{"click":function($event){return _vm.onFavoriteClick()}}},[_c('p',[_vm._v(_vm._s(_vm.data.favorites !== undefined ? _vm.data.favorites.length : 0))]),(
          _vm.user !== null &&
          _vm.data.favorites !== undefined &&
          !_vm.data.favorites.includes(_vm.user.uid)
        )?_c(VIcon,{attrs:{"color":"white","size":"15"}},[_vm._v("mdi-heart-outline")]):_c(VIcon,{attrs:{"color":"white","size":"15"}},[_vm._v("mdi-heart")])],1)]),_c('div',{staticClass:"token-image",on:{"click":function($event){return _vm.onClick(_vm.data.tokenId)}}},[_c('div',{staticClass:"sub-class-token"},[_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"contain"},attrs:{"src":_vm.data.placeholder}})])]),_c('div',{staticClass:"token-footer"},[_c('div',{staticClass:"token-title"},[_vm._v(_vm._s(_vm.data.name))]),_c('div',{staticClass:"token-desc"},[_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.data.description)+" ")]),(_vm.data.price !== 0 || _vm.data.price.length !== 0)?_c('div',{staticClass:"price"},[_c(VIcon,{attrs:{"color":"white","size":"20"}},[_vm._v("mdi-currency-usd")]),_c('span',[_vm._v(_vm._s(_vm.data.price))])],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }