<template>
  <div class="skills-container">
    <h3 class="secondary-heading heading">Skills</h3>
    <v-row class="mt-0">
      <v-col lg="4">
        <div class="skill-content content">
          <h4 class="content-heading">SPECIALIZATION</h4>
          <div class="skills-available mt-1">
            <div class="soft-tags mt-3" v-if="profileData.specialization <= 4">
              <div class="tags" v-for="(tag, i) in profileData.specialization" :key="i">
                <div class="tag">{{ tag }}</div>
              </div>
            </div>
            <div class="soft-tags mt-3" v-else>
              <div class="tags" v-for="(tag, i) in profileData.specialization.slice(0, 5)" :key="i">
                <div class="tag">{{ tag }}</div>
              </div>
            </div>            
          </div>
        </div>
      </v-col>
      <v-col lg="4">
        <div class="skill-content content">
          <h4 class="content-heading">TOOLS</h4>
          <div class="skills-available mt-1">
            <v-row>
              <v-col lg="3" v-for="(tool, index) in profileData.toolsUsed" :key="index">
                <div class="image" v-if="tools[`${tool}`]!=null">
                  <img :src="tools[`${tool}`]" />
                  <p>{{tool}}</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col lg="4">
        <div class="skill-content content">
          <h4 class="content-heading">PROJECTS INTERESTED</h4>
          <div class="skills-available mt-1">
            <div class="soft-tags mt-3" v-if="profileData.projectInterest.length <= 4">
              <div class="tags" v-for="(tag, i) in profileData.projectInterest" :key="i">
                <div class="tag">{{ tag }}</div>
              </div>
            </div>
            <div class="soft-tags mt-3" v-else>
              <div class="tags" v-for="(tag, i) in profileData.projectInterest.slice(0, 5)" :key="i">
                <div class="tag">{{ tag }}</div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import ToolsList from '../../../helpers/tools.json';

export default {
  name: "Skill-Component",
  props: ["profileData"],
  data() {
    return {
      tools: ToolsList
    };
  },
};
</script>
<style scoped>
.skills-container {
  width: 100%;
}
.heading {
  font-size: 20px;
  width: 10%;
  font-weight: bold;
}
.content {
  background-color: #1b1b23;
}
.skill-content {
  width: 100%;
  height: 30vh;
  border-radius: 10px;
  padding: 10px;
}
.content-heading {
  color: rgba(255, 255, 255, 0.75);
}
.skills {
  padding: 5px;
  border-radius: 10px;
  color: #ff7f3f;
  font-size: 15px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
}
.tags {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}

.soft-tags {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.tag {
  padding: 5px;
  border-radius: 5px;
  color: #ff7f3f;
  font-size: 12px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.image{
  width: 40px;
  height: 40px;
}
.image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.image > p{
  margin: 0px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 8px;
}
</style>
